@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.glow {
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.623);
}

.section_with_blur {
  position: relative;
  width: 100%;
  /* height: 400px; */
}

.section_with_blur::before {
  content: '';
  position: absolute;
  top: -100px;
  left: 50%;
  width: 80%;
  height: 70%;
  background: rgba(5, 119, 131, 0.4);
  border-radius: 50%;
  transform: translateX(-50%);
  filter: blur(200px);
  z-index: -10;
}

.bottomLightBg {
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(22, 131, 141, 1),
    rgba(20, 119, 129, 0.6),
    rgba(2, 12, 13, 0.14),
    rgba(7, 22, 18, 0),
    rgba(0, 0, 0, 0),
    rgba(20, 119, 129, 0)
  );
  @media (min-width: 450px) {
    background: conic-gradient(
      from 180deg at 51% 51%,
      rgb(9, 170, 185),
      rgba(5, 119, 131, 0.4) 45deg,
      rgba(1, 35, 35, 0.14),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(20, 119, 129, 0.1)
    );
  }
}

body {
  background-color: #020c0d;
}

.borderGrd {
  background:
    linear-gradient(#020c0d, #020c0d) padding-box,
    linear-gradient(to bottom, rgb(141, 141, 141), #1e1e1e) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
  transition: border 1s;
}

.borderGrd:hover {
  background:
    linear-gradient(#020c0d, #020c0d) padding-box,
    linear-gradient(to bottom, rgb(141, 141, 141), rgb(18, 159, 177)) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
}

.blogBtn {
  background-color: #eefeff;
}

.story-card-grd {
  /* background: radial-gradient(169.40% 89.55% at 94.76% 6.29%, #FFFFFF 88%, #404040 100%), */
  background: radial-gradient(#151d1f 20%, #020c0d 100%);
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --swiper-pagination-color: white;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-pagination-bullet-inactive-color: #b9b9b9;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

.tech-stack {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 30px rgba(0, 217, 255, 0.23);
  border: 1px solid rgba(0, 255, 255, 0.616);
  transition: all 0.5s;
  scale: 1.012;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgb(255, 255, 255);
  right: 5px;
  padding: 40px;
  color: #000000 !important;
  fill: rgb(136, 136, 136) !important;
  stroke: rgb(239, 239, 239) !important;
  border-radius: 100%;
  scale: 0.4;
}

/* .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@layer utilities {
  .article-section blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    @apply my-3 lg:my-5;
  }
  .article-section a {
    @apply text-accent-blue;
  }
  .article-section strong {
    @apply font-bold;
  }
  .article-section em {
    @apply text-white;
  }
  .article-section img {
    @apply w-full;
  }
  .article-section p {
    @apply lg:text-lg font-light text-[#d6d6d6];
  }
  .article-section h1 {
    @apply lg:text-3xl text-2xl font-semibold;
  }
  .article-section h2,
  h3,
  h4,
  h5,
  h6 {
    @apply lg:text-xl text-lg font-semibold;
  }
} */

.myPlayer {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;
  pointer-events: none;
  color: transparent;
}

.myPlayer iframe {
  width: 700%;
  height: 100%;
  margin-left: -300%;
}

.herologos svg {
  width: 150px !important;
  height: 45px !important;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .herologos svg {
    height: 35px !important;
  }
}

.chevron {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  flex-shrink: 0;
}

.chevron::before,
.chevron::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 1.5px;
  /* border-radius: 1px; */
  background: rgb(255, 255, 255);
  transition: transform 0.3s ease;
}

.chevron::before {
  top: 50%;
  left: 0;
  transform: rotate(46deg);
}

.chevron::after {
  top: 50%;
  right: -0.5px;
  transform: rotate(-46deg);
}

.chevron.up::before {
  transform: rotate(-46deg);
}

.chevron.up::after {
  transform: rotate(46deg);
}

.chevron.down::before {
  transform: rotate(46deg);
}

.chevron.down::after {
  transform: rotate(-46deg);
}

/* New hero section */

.dash {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.star {
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 50%;
}

@keyframes twinkle {
  0% {
    transform: scale(1, 1);
    background: rgba(255, 255, 255, 0);
    animation-timing-function: linear;
  }
  40% {
    transform: scale(0.8, 0.8);
    background: rgba(255, 255, 255, 1);
    animation-timing-function: ease-out;
  }
  80% {
    background: rgba(255, 255, 255, 0);
    transform: scale(1, 1);
  }
  100% {
    background: rgba(255, 255, 255, 0);
    transform: scale(1, 1);
  }
}

.navbar {
  box-shadow: 0px 75px 67px -65px #5bd4dd;
}

.shadowd {
  -webkit-box-shadow: 4px 7px 209px 52px rgba(255, 255, 255, 1);
  -moz-box-shadow: 4px 7px 209px 52px rgba(255, 255, 255, 1);
  box-shadow: 4px 7px 209px 52px rgba(255, 255, 255, 1);
}

.text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 9;
}

.animated-border-box {
  /* max-height: 74px;
  max-width: 250px;
  height: 100%;
  width: 100%; */
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated-border-box-cta {
  /* max-height: 74px;
  max-width: 250px;
  height: 100%;
  width: 100%; */
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animated-border-nav {
  /* max-height: 74px;
  max-width: 250px;
  height: 100%;
  width: 100%; */
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: '';
  z-index: 2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 316px;
  height: 316px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #fff,
    rgba(0, 0, 0, 0) 25%
  );
  animation: rotate 6s linear infinite;
}

.animated-border-box-cta:before,
.animated-border-box-glow-cta:before {
  content: '';
  z-index: 2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 316px;
  height: 316px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.5) rgba(0, 0, 0, 0) 25%
  );
  animation: rotate 6s linear infinite;
}

.flashlight {
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 716px;
  height: 716px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0) 25%
  );
  animation: rotate 6s linear infinite;
}

.flashlight1 {
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 416px;
  height: 416px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0) 25%
  );
  animation: rotate 6s linear infinite;
}


.animated-border-box:after {
  content: '';
  position: absolute;
  z-index: 2;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #1d1e22;
  border-radius: 50px;
}

.animated-border-box-cta:after {
  content: '';
  position: absolute;
  z-index: 2;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #1d1e226a;
  border-radius: 50px;
}

.animated-border-box-nav:after {
  content: '';
  position: absolute;
  z-index: 2;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  /* background: rgba(255, 255, 255, 0.06); */
  background: #0f1719;
  border-radius: 50px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

.container {
  position: relative;
  width: 100%;
  height: 489px;
}

.blurred-div {
  position: absolute;
  /*   inset: 0; */
  left: 0;
  right: 0;
  top: 0;
  height: 100px;
  /*   background: radial-gradient(circle, rgb(255,255,255, 0.5), rgba(0,0,0,0)); */
  background: #9d6dbb;
  filter: blur(1px);
  animation: moveUpDown 5s ease-in-out infinite;
  z-index: 0;
}

@keyframes moveUpDown {
  100% {
    transform: translateY(800px);
  }
  50% {
    transform: translateY(0);
  }
}

.mask {
  -webkit-mask-image: url(https://i.ibb.co/FnTRz4X/svgviewer-output-1.png);
  mask-image: url(https://i.ibb.co/FnTRz4X/svgviewer-output-1.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  width: max-content;
}

.radial {
  background-image: radial-gradient(
    closest-side,
    rgba(27, 199, 199, 0.3) 0%,
    rgba(27, 199, 199, 0) 62%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(100px);
}

.radialbottom {
  background-image: radial-gradient(
    closest-side,
    rgba(27, 199, 199, 1) 0%,
    rgba(27, 199, 199, 0) 52%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(100px);
}

.navbarOuter {
  /* Navigation bar */

  /* Auto layout */
}

.motion-container {
  overflow: hidden; /* Ensures children don't break out of the shrinking container */
  position: relative; /* Ensures the layout stays intact */
}

.motion-container > * {
  position: relative; /* Makes sure child elements remain stationary */
  transform: none; /* Prevents unintended scaling or movement */
}

.shine {
  width: 1500px;
  height: 150px;
  margin-left: -100px;
  transform: rotate(-30deg);
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(15, 93, 102, 0.1),
    transparent
  );
  position: absolute;
  animation: shine 4s ease-in-out infinite;
  animation-delay: 1s; /* Add a 1-second delay */
}

@keyframes shine {
  0% {
    margin-top: -250px;
  }
  100% {
    margin-top: 1000px;
  }
}

.top-gradient {
  height: 1000px;
  width: 2000px;
  background-image: radial-gradient(
    closest-side,
    rgba(68, 142, 148, 1),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  top: -770px;
  filter: blur(50px);
}

.bottom-gradient {
  height: 1000px;
  width: 2000px;
  background-image: radial-gradient(
    closest-side,
    rgba(68, 142, 148, 1),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  top: 570px;
  filter: blur(50px);
}
.contact-shadow {
  -webkit-box-shadow: 0px 0px 98px -14px rgb(255, 255, 255);
  -moz-box-shadow: 0px 0px 98px -14px rgb(255, 255, 255);
  box-shadow: 0px 0px 98px -14px rgba(255, 255, 255, 0.555);
}

